import * as React from "react";

import Content from "../components/content";
import ContentTitle from "../components/content-title";
import Layout from "../components/layout";
import { SEO } from "../components/seo";

export const Head = () => <SEO />;

const PrivacyPolicy = () => {
  return (
    <Layout headerSolid activeTab={null}>
      <ContentTitle />
      <Content />
    </Layout>
  );
};

export default PrivacyPolicy;
